// import img
import { imagenesPlantillas } from "./imagenesPlantillas";
// import logos tecnologias
import { imgTecnologias } from "./imagenesPlantillas";
// elementos para descargar
import E_commerce_Plantas_Carnivoras from "../Plantillas/descargaPlantillas/E-commerce-Plantas-Carnivoras.zip";
import E_commerce_Fitness from "../Plantillas/descargaPlantillas/E-commerce-Fitness.zip";
import E_commerce_ropa_deportiva from "../Plantillas/descargaPlantillas/E-commerce-ropa-deportiva.zip";
const ImpactoMercadoEcommerce =
  "Los consumidores prefieren cada vez más realizar compras en línea, cambiando los hábitos de compra tradicionales y llevando a un aumento en las ventas de e-commerce.";
const ecommerceVentajas =
  "Más rápida, eficiente y versátil que las tiendas de e-commerce como Shopify o Tienda Nube, gracias al stack de tecnologías utilizadas para su creación.";

const Plantillas = [
  {
    id: 1,
    video: imagenesPlantillas[0],
    imagen: imagenesPlantillas[1],
    imagen2: imagenesPlantillas[2],
    imagen3: imagenesPlantillas[3],
    imagen4: imagenesPlantillas[4],
    descargar: E_commerce_Plantas_Carnivoras,
    titulo: "Tienda E-commerce con Dashboard",
    ruta: "/plantillas",
    textBtn: "consultar",
    precio: 5000,
    cantVendidas: 11,
    // precioViejo: "",
    comprar: "comprar",
    // Tecnologias: "HTML, CSS Y JAVASCRIPT.",
    // tecnologias1: imgTecnologias[3],
    tecnologias2: imgTecnologias[8],
    tecnologias3: imgTecnologias[1],
    tecnologias4: imgTecnologias[6],
    tecnologias5: imgTecnologias[5],
    alt: "plantilla sitio web ecommerce tiendas online de coderplate40 ",
    text: `Tienda Web Ecommerce Completa con Dashboard/Panel de Administrador *PROYECTO REAL* llevado y testeado en producción Desarrollado en ReactJS, NodeJS y MongoDB. Son códigos individuales cada uno con SSR y su Respectiva Documentación.`,
    ventajas: ecommerceVentajas,
    impactoMercado: ImpactoMercadoEcommerce,
  },
  // {
  //   id: 2,
  //   video: imagenesPlantillas[5],
  //   imagen: imagenesPlantillas[6],
  //   imagen2: imagenesPlantillas[7],
  //   imagen3: imagenesPlantillas[8],
  //   imagen4: imagenesPlantillas[9],
  //   descargar: E_commerce_Fitness,
  //   titulo: "E-commerce Fitness",
  //   ruta: "/plantillas",
  //   textBtn: "consultar",
  //   precio: "2.99",
  //   precioViejo: "9.99",
  //   tecnologias1: imgTecnologias[0],
  //   tecnologias2: imgTecnologias[1],
  //   tecnologias3: imgTecnologias[2],
  //   tecnologias4: imgTecnologias[4],
  //   alt: "plantilla E-commerce Fitness en tienda de coderplate40",
  //   text: "Tienda virtual de Fitness con diseño moderno y responsive. Incluye un carrito de compras para una experiencia de compra sin complicaciones y un formulario de contacto integrado. Resalta con un atractivo slider de imágenes que muestra la versatilidad y estilo de la colección para el Fitness.",
  //   descripcion:
  //     "Los sitios de portafolios son plataformas diseñadas para mostrar trabajos, proyectos o habilidades de un individuo o empresa. Comúnmente utilizados por profesionales creativos como diseñadores, fotógrafos y desarrolladores.",
  //   ventajas: ecommerceVentajas,
  //   impactoMercado: ImpactoMercadoEcommerce,
  // },
  // {
  //   id: 3,
  //   video: imagenesPlantillas[10],
  //   imagen: imagenesPlantillas[11],
  //   imagen2: imagenesPlantillas[12],
  //   imagen3: imagenesPlantillas[13],
  //   imagen4: imagenesPlantillas[14],
  //   descargar: E_commerce_ropa_deportiva,
  //   titulo: "E-commerce Ropa deportiva",
  //   ruta: "/plantillas",
  //   textBtn: "consultar",
  //   precio: "2.99",
  //   precioViejo: "9.99",
  //   tecnologias1: imgTecnologias[0],
  //   tecnologias2: imgTecnologias[1],
  //   tecnologias3: imgTecnologias[2],
  //   tecnologias4: imgTecnologias[4],
  //   alt: "plantilla E-commerce Ropa Deportiva en tienda de coderplate40",
  //   descripcion:
  //     "Los sitios de portafolios son plataformas diseñadas para mostrar trabajos, proyectos o habilidades de un individuo o empresa. Comúnmente utilizados por profesionales creativos como diseñadores, fotógrafos y desarrolladores.",
  //   ventajas: ecommerceVentajas,
  //   impactoMercado: ImpactoMercadoEcommerce,
  //   text: "Tienda virtual de ropa deportiva con diseño moderno y responsive. Incluye un carrito de compras para una experiencia de compra sin complicaciones y un formulario de contacto integrado. Resalta con un atractivo slider de imágenes que muestra la versatilidad y estilo de la colección deportiva.",
  // },
  // {
  //   id: 4,
  //   video: imagenesPlantillas[15],
  //   imagen: imagenesPlantillas[16],
  //   imagen2: imagenesPlantillas[17],
  //   imagen3: imagenesPlantillas[18],
  //   imagen4: imagenesPlantillas[19],
  //   descargar: E_commerce_ropa_deportiva,
  //   titulo: "E-commerce Tecnología",
  //   ruta: "/plantillas",
  //   textBtn: "consultar",
  //   precio: "2.99",
  //   precioViejo: "9.99",
  //   tecnologias1: imgTecnologias[0],
  //   tecnologias2: imgTecnologias[1],
  //   tecnologias3: imgTecnologias[2],
  //   tecnologias4: imgTecnologias[4],
  //   descripcion:
  //     "Los sitios educativos proporcionan contenido educativo, cursos en línea, tutoriales y recursos para el aprendizaje. Pueden variar desde plataformas formales de educación en línea hasta blogs educativos.",
  //   ventajas: ecommerceVentajas,
  //   impactoMercado: ImpactoMercadoEcommerce,
  //   text: "Tienda virtual de Tecnología con diseño moderno y responsive. Incluye un carrito de compras para una experiencia de compra sin complicaciones y un formulario de contacto integrado. Resalta con un atractivo slider de imágenes que muestra la versatilidad y estilo de la colección Tecnología.",
  // },
  // {
  //   id: 4,
  //   imagen: ForosyComunidadesenLínea,
  //   titulo: "Foros En línea",
  //   ruta: "/plantillas",
  //   textBtn: "consultar",
  //   precio: "desde $199.99USD",
  //   precioViejo: "$350USD",
  //   descripcion:
  //     "Los foros en línea son plataformas de discusión donde los usuarios pueden intercambiar ideas, hacer preguntas y compartir información sobre diversos temas. Pueden ser especializados o cubrir una amplia gama de temas.",
  //   ventajas: `Comunidad Fomenta la interacción y construcción de comunidades.
  //   Resolución de Problemas Los usuarios pueden obtener ayuda para problemas específicos.
  //   Intercambio de Conocimientos Facilita el intercambio de información y experiencias.`,
  //   impactoMercado:
  //     "Los foros en línea han sido fundamentales para la creación de comunidades en línea y han desempeñado un papel importante en la difusión de conocimientos y la conexión de personas con intereses similares.",
  //   text: "Plataforma interactiva que permite a los usuarios discutir temas, hacer preguntas y compartir información en línea.",
  // },
  // {
  //   id: 5,
  //   imagen: SitiosWebdeViajesLandingPage,
  //   titulo: "Landing Page",
  //   ruta: "/plantillas",
  // textBtn: "consultar",
  //   precio: "desde $99.99USD",
  //   precioViejo: "$200USD",
  //   descripcion:
  //     "Una landing page es una página web diseñada específicamente para convertir visitantes en leads o clientes. Se centra en una llamada a la acción (CTA) clara y suele ser parte de una estrategia de marketing.",
  //   ventajas: `Enfoque Singular Se centra en un objetivo específico.
  //   Conversión Efectiva Diseñada para maximizar la tasa de conversión.
  //   Rastreo y Análisis Simplificados Facilita el seguimiento de métricas específicas.
  //   `,
  //   impactoMercado:
  //     "Las landing pages son fundamentales en campañas de marketing digital, ya que proporcionan un punto de entrada focalizado para convertir visitantes en clientes potenciales o clientes.",
  //   text: "Una landing page es una página web diseñada con un objetivo específico, como promocionar un producto, captar leads o incentivar una acción.",
  // },
  // {
  //   id: 6,
  //   imagen: Ecommerce,
  //   titulo: "E-commerce",
  //   ruta: "/plantillas",
  // textBtn: "consultar",
  //   precio: "desde $399.99USD",
  //   precioViejo: "$600USD",
  //   descripcion:
  //     "Los sitios de comercio electrónico permiten la compra y venta de productos o servicios en línea. Pueden variar desde pequeñas tiendas en línea hasta grandes plataformas de comercio electrónico.",
  //   ventajas: `Acceso Global al Mercado Amplía el alcance de los negocios.
  //   Facilidad de Compra Permite a los clientes comprar desde la comodidad de sus hogares.
  //   Personalización Ofrece la posibilidad de personalizar la experiencia de compra.`,
  //   impactoMercado:
  //     "El comercio electrónico ha revolucionado la forma en que se realizan las transacciones comerciales, permitiendo a las empresas llegar a clientes de todo el mundo y brindar opciones de compra convenientes.",
  //   text: "Un sitio web de comercio electrónico permite la compra y venta de productos o servicios en línea.",
  // },
  // // {
  // //   id: 7,
  // //   imagen: PáginasWebDinámicas_Especiales,
  // //   titulo: "PiedraPapelOTijerasTitulo",
  // //   ruta: "/plantillas",
  // textBtn: "consultar",
  // //   precio: "desde $49.99USD",
  // //   descripcion: "",
  // //   ventaja: "",
  // //   impactoMercado: "",
  // //   precioViejo: "$100USD",
  // //   text: "textPiedraPapelOTijeras",
  // // },
  // // {
  // //   id: 9,
  // //   imagen: SimonGame,
  // //   titulo: "SimonGameTitulo",
  // //   tecnologias: "HTML, CSS and JS",
  // //   // ruta: "/plantillas",
  // textBtn: "consultar",
  // //   text: "textSimonGame",
  // //   linkWebside: "https://simon-dice-js.netlify.app/",
  // //   linkRepository: "https://github.com/kevingonzalo/simon-dice-js",
  // // },
  // // {
  // //   id: 10,
  // //   imagen: TaTeTiGame,
  // //   titulo: "Ta-Te-Ti Game",
  // //   tecnologias: "Reactjs.",
  // //    ruta: "/plantillas",
  // textBtn: "consultar",
  // //   text: "textTaTeTiGame",
  // //   linkWebside: "https://ta-te-ti-reactjs.netlify.app/",
  // //   linkRepository: "https://github.com/kevingonzalo/Ta-Te-Ti-REACTJS",
  // // },
  // // {
  // //   id: 11,
  // //   imagen: CalculadoraJs,
  // //   titulo: "CalculadoraJsTitulo",
  // //   tecnologias: "HTML, CSS and JavaScript.",
  // //   ruta: "/plantillas",
  // textBtn: "consultar",
  // //   text: "textCalculadoraJs",
  // //   linkWebside: "https://calculadorawithjs.netlify.app/",
  // //   linkRepository: "https://github.com/kevingonzalo/Calculadora-js",
  // // },
  // // {
  // //   id: 12,
  // //   imagen: CuentaRegresiva,
  // //   titulo: "Ta-Te-Ti Game",
  // //   tecnologias: "HTML, CSS and JavaScript.",
  // //    ruta: "/plantillas",
  // textBtn: "consultar",
  // //   text: "textCuentaRegresiva",
  // //   linkWebside: "https://cuenta-regresivajs.netlify.app/",
  // //   linkRepository: "https://github.com/kevingonzalo/Cuenta-Regresiva-JS",
  // // },
];
export default Plantillas;
