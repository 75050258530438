// import logos tecnologias
import html from "./multimediaPlantillas/img/img-logos/html-5.svg";
import css from "./multimediaPlantillas/img/img-logos/css-3.svg";
import js from "./multimediaPlantillas/img/img-logos/js.svg";
import react from "./multimediaPlantillas/img/img-logos/react.svg";
import bootstrap from "./multimediaPlantillas/img/img-logos/bootstrap.svg";
import materialui from "./multimediaPlantillas/img/img-logos/material-ui.svg";
import tailwindcss from "./multimediaPlantillas/img/img-logos/tailwindcss.svg";
import sass from "./multimediaPlantillas/img/img-logos/sass.svg";
import nodejs from "./multimediaPlantillas/img/img-logos/nodejs.svg";
import express from "./multimediaPlantillas/img/img-logos/express.svg";
import typescript from "./multimediaPlantillas/img/img-logos/typescript.svg";
import nextjs from "./multimediaPlantillas/img/img-logos/nextjs.svg";
import mysql from "./multimediaPlantillas/img/img-logos/mysql.svg";
import mongodb from "./multimediaPlantillas/img/img-logos/mongodb.svg";

// plantilla 1
import ecommercePlantasCarnivoras from "./multimediaPlantillas/img/plantilla-ecommerce-plantas-carnivoras/2.png";
import ecommercePlantasCarnivoras2 from "./multimediaPlantillas/img/plantilla-ecommerce-plantas-carnivoras/1.jpg";
import ecommercePlantasCarnivoras3 from "./multimediaPlantillas/img/plantilla-ecommerce-plantas-carnivoras/3.png";
import ecommercePlantasCarnivoras4 from "./multimediaPlantillas/img/plantilla-ecommerce-plantas-carnivoras/4.png";
import E_commerce_Plantas_carnivorasVideo from "./multimediaPlantillas/video/E-commerce-Plantas-carnivoras.mp4";
// plantilla 2
import PlantillaFitness from "./multimediaPlantillas/img/plantilla-Fitness/Plantilla-Fitness.webp";
import PlantillaFitness2 from "./multimediaPlantillas/img/plantilla-Fitness/Plantilla-Fitness2.webp";
import PlantillaFitness3 from "./multimediaPlantillas/img/plantilla-Fitness/Plantilla-Fitness3.webp";
import PlantillaFitness4 from "./multimediaPlantillas/img/plantilla-Fitness/Plantilla-Fitness4.webp";
import E_commerce_FitnessVideo from "./multimediaPlantillas/video/E-commerce-Fitness.mp4";
// plantilla 3
import PlantillaRopaDeportiva from "./multimediaPlantillas/img/plantilla-ecommerce-ropa-deportiva/ecommerce-ropa-deportiva-img1.webp";
import PlantillaRopaDeportiva2 from "./multimediaPlantillas/img/plantilla-ecommerce-ropa-deportiva/ecommerce-ropa-deportiva-img2.webp";
import PlantillaRopaDeportiva3 from "./multimediaPlantillas/img/plantilla-ecommerce-ropa-deportiva/ecommerce-ropa-deportiva-img3.webp";
import PlantillaRopaDeportiva4 from "./multimediaPlantillas/img/plantilla-ecommerce-ropa-deportiva/ecommerce-ropa-deportiva-img4.webp";
import PlantillaRopaDeportivaVideo from "./multimediaPlantillas/video/E-commerce-ropa-deportiva.mp4";
// plantilla 4
import PlantillaTecnlogia from "./multimediaPlantillas/img/plantillas-ecommerce-tecnologia/Plantilla-Tecnlogia-img.webp";
import PlantillaTecnlogia2 from "./multimediaPlantillas/img/plantillas-ecommerce-tecnologia/Plantilla-Tecnlogia-img2.webp";
import PlantillaTecnlogia3 from "./multimediaPlantillas/img/plantillas-ecommerce-tecnologia/Plantilla-Tecnlogia-img3.webp";
import PlantillaTecnlogia4 from "./multimediaPlantillas/img/plantillas-ecommerce-tecnologia/Plantilla-Tecnlogia-img4.webp";
import PlantillaTecnlogiaVideo from "./multimediaPlantillas/video/E-commerce-tecnologia.mp4";

export const imagenesPlantillas = [
  E_commerce_Plantas_carnivorasVideo,
  ecommercePlantasCarnivoras,
  ecommercePlantasCarnivoras2,
  ecommercePlantasCarnivoras3,
  ecommercePlantasCarnivoras4,
  E_commerce_FitnessVideo,
  PlantillaFitness,
  PlantillaFitness2,
  PlantillaFitness3,
  PlantillaFitness4,
  PlantillaRopaDeportivaVideo,
  PlantillaRopaDeportiva,
  PlantillaRopaDeportiva2,
  PlantillaRopaDeportiva3,
  PlantillaRopaDeportiva4,
  PlantillaTecnlogiaVideo,
  PlantillaTecnlogia,
  PlantillaTecnlogia2,
  PlantillaTecnlogia3,
  PlantillaTecnlogia4,
];

export const imgTecnologias = [
  html,
  css,
  js,
  react,
  bootstrap,
  materialui,
  tailwindcss,
  sass,
  nodejs,
  express,
  typescript,
  nextjs,
  mysql,
  mongodb,
];
