import SeparadorTitulo from "../../Componentes_individuales/SeparadorTitulo";
import Titulo from "../../Componentes_individuales/Titulo";
import "./AboutUs.css";
import Proyecto from "./Proyecto";
import imgAboutme from "../../../img/img-aboutme.webp";
import imgAboutme1 from "../../../img/img-aboutme1.webp";
import { Link } from "react-router-dom";
const AboutUs = () => {
  return (
    <section id="Quienes-Somos">
      <div className="container-aboutUs">
        <div className="container-tittle-aboutUs">
          <Titulo titulo="Sobre Nosotros" />
          <SeparadorTitulo />
        </div>
        <div className="content-aboutUS">
          <div className="aboutus-contenido-interno">
            <div className="container-description-aboutus">
              <h1>¿Quienes Somos?</h1>
              <div className="quienes-somos-container">
                <div className="quienes-somos-top">
                  <div>
                    <img
                      src={imgAboutme1}
                      alt="empresa de desarrollo web - coderplate40"
                    />
                  </div>
                  <p>
                    coderplate40 es una empresa en el ámbito de los servicios
                    digitales, dedicada a impulsar la transformación de ideas y
                    el éxito. Con un enfoque apasionado en la tecnología y la
                    creatividad, hemos consolidado nuestra posición de liderazgo
                    en la industria digital. Brindamos una amplia gama de
                    servicios digitales, incluyendo el desarrollo de sitios
                    web.💻
                  </p>
                </div>
                <div className="quienes-somos-bottom">
                  <p>
                    Nuestro equipo está conformado por profesionales en diseño,
                    desarrollo y marketing digital. Cada miembro se dedica
                    plenamente a ofrecer soluciones digitales de alta calidad,
                    diseñadas a medida para cumplir con los objetivos de
                    nuestros clientes 🤙. En coderplate40, estamos comprometidos
                    a ayudar a las empresas a alcanzar su máximo potencial en el
                    mundo digital, impulsando su presencia en línea y generando
                    resultados significativos.😄
                  </p>
                  <div>
                    <img
                      src={imgAboutme}
                      alt="empresa de desarrollo web - coderplate40"
                    />
                  </div>
                </div>
                <div className="btn about-me">
                  <Link to="/SitiosWeb" offset={0} duration={450}>
                    <span>Solicitar Servicio📞</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
