import { useState } from "react";
import SeparadorTitulo from "../../Componentes_individuales/SeparadorTitulo";
import Titulo from "../../Componentes_individuales/Titulo";
import "./Contact.css";
import { useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import { URL } from "../../../config";
import BackBtn from "../BackBtn";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Contacto = ({ ocultarBtn }) => {
  const [value1, setValue1] = useState();
  const [value2, setValue2] = useState();
  // const [value3, setValue3] = useState(Asunto);
  const [value4, setValue4] = useState();
  const [value5, setValue5] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isConsultar = location.pathname.includes("/consultar");

  const formData = {
    nombre: value1,
    email: value2,
    // Asunto: value3,
    telefono: value4,
    mensaje: value5,
  };

  const notify = (cond, mensaje) =>
    cond ? toast.success(mensaje) : toast.error(mensaje);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const response = await axios.post(`${URL}/enviar-mail`, formData);
      if (response.status === 200) {
        setValue1("");
        setValue2("");
        // setValue3("");
        setValue4("");
        setValue5("");
        notify(true, "Formulario enviado Correctamente");
      } else if (response.status === 500) {
        notify(false, "Hubo un error al Enviar el Formulario");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleInputChange = (event) => {
    switch (event.target.id) {
      case "1":
        setValue1(event.target.value);
        break;
      case "2":
        setValue2(event.target.value);
        break;
      // case "3":
      //   setValue3(event.target.value);
      //   break;
      case "4":
        setValue4(event.target.value);
        break;
      case "5":
        setValue5(event.target.value);
        break;
      default:
        console.log("ID no válido");
    }
  };
  // useEffect(() => {
  //   setValue3(Asunto);
  // }, [Asunto]);
  return (
    <section id="Contacto">
      {/* {ocultarBtn && (
        <div className="btn-volver">
          <div className="btn-bg bg-1">
            <div className="btn-consultar btn-1">
              <Link to="/">
                <button>Volver</button>
              </Link>
            </div>
          </div>
        </div>
      )} */}
      {isConsultar && (
        <Link className="btn-voler" onClick={() => navigate(-1)}>
          Volver
        </Link>
      )}

      <div className="box-form">
        <div className="tittle-contact">
          <Titulo titulo="Contacto" />
          <SeparadorTitulo />
        </div>
        <p className="subtitulo-contacto">
          Por favor, envíanos un mensaje con los detalles de tu proyecto y te
          responderemos a la brevedad.
        </p>
        <form onSubmit={handleSubmit} className="form-contact">
          <div className="input-container">
            <input
              type="text"
              id="1"
              onChange={handleInputChange}
              className="input-contacto"
              value={value1}
            />
            <label
              className={
                value1 ? "input-label input-label-active" : "input-label"
              }
            >
              Nombre
            </label>
          </div>
          <div className="input-container">
            <input
              type="text"
              id="2"
              onChange={handleInputChange}
              className="input-contacto"
              pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
              required
              value={value2}
            />
            <label
              className={
                value2 ? "input-label input-label-active" : "input-label"
              }
            >
              Email
            </label>
            <div className="two-inputs">
              {/* <div className="input-container">
              <input type="text" id="3" onChange={handleInputChange} className="input-contacto" value={value3} />
              <label className={value3 ? "input-label input-label-active" : "input-label"}>Asunto</label>
            </div> */}
              <div></div>
              <div className="input-container">
                <input
                  type="text"
                  id="4"
                  onChange={handleInputChange}
                  className="input-contacto"
                  value={value4}
                />
                <label
                  className={
                    value4 ? "input-label input-label-active" : "input-label"
                  }
                >
                  Teléfono
                </label>
              </div>
            </div>
          </div>
          <div className="input-container">
            <textarea
              type="text"
              id="5"
              onChange={handleInputChange}
              className="input-contacto"
              style={{ height: "15vh" }}
              required
              value={value5}
            />
            <label
              className={
                value5 ? "input-label2 input-label-active2" : "input-label2"
              }
            >
              Mensaje
            </label>
          </div>
          {loading && (
            <div
              className="spinner-border spinner"
              role="status"
              style={{
                color: "white",
                position: "relative",
              }}
            >
              <span
                className="visually-hidden"
                style={{ position: "absolute" }}
              >
                Loading...
              </span>
            </div>
          )}
          <div style={{ paddingTop: "2rem" }}>
            <button type="submit" className="button">
              Enviar consulta
              <div className="button__horizontal"></div>
              <div className="button__vertical"></div>
            </button>
          </div>
        </form>
        <div>
          <ToastContainer
            position="bottom-left"
            style={{
              maxWidth: "300px",
              position: "fixed",
              marginBottom: "3vh",
            }}
            autoClose={2000}
          />
        </div>
      </div>
    </section>
  );
};

export default Contacto;
