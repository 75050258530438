import React, { useEffect, useState } from "react";
// formateo Precio
export const formatearPrecio = (precio) => {
  if (typeof precio !== "number" || isNaN(precio)) {
    return "0"; // Retorna un valor predeterminado si el precio no es válido
  }

  // Formatear el número
  const opciones = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  // Si el número tiene decimales distintos de 00, mostrarlos, de lo contrario, mostrar sin decimales
  return precio % 1 === 0
    ? precio.toLocaleString("es-AR", { ...opciones, minimumFractionDigits: 0 })
    : precio.toLocaleString("es-AR", opciones);
};

export default function ComponentService(props) {
  return (
    <>
      <a
        href={`${props.ruta}/${props.titulo}`}
        style={{ color: "white", textDecoration: "none" }}
        className="tarjeta-plantilla"
      >
        <div className="project-img">
          <img src={props.imagen} alt={props.alt} />
          <h1>{props.titulo}</h1>

          {/* {props.precio != 0 && (
            <p>
              <strong>${formatearPrecio(props.precio)} ARS o $5 USD</strong>
            </p>
          )} */}
        </div>
        <br />
        <div className="projects-item-description">
          <button
            style={{ color: "white", background: "black" }}
            onClick={() => (window.location.href = "/consultar")}
          >
            Consultar
          </button>
        </div>
      </a>
    </>
  );
}
