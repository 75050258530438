import React from "react";
import "./styles/terminosycondiciones.css";
import BackBtn from "./common/BackBtn";
export default function TerminosYCondiciones() {
  return (
    <div className="contenedor-principal">
      <div style={{ marginLeft: "10%" }}>
        <BackBtn ruta="/" />
      </div>
      <div className="Terminos-Y-condiciones-container">
        <h1>Términos y Condiciones de Uso - coderplate40</h1>
        <h4>
          <strong>
            Bienvenido a coderplate40. Al acceder y utilizar nuestros servicios,
            aceptas los siguientes términos y condiciones:
          </strong>
        </h4>
        <p>
          Aceptación de Términos: Al registrarte, acceder o utilizar cualquier
          parte de los servicios de coderplate40, aceptas estos términos y
          condiciones y nuestra política de privacidad. Si no estás de acuerdo,
          por favor, no uses nuestros servicios. Medios de Pago: coderplate40
          utiliza servicios de pago en línea, como PayPal y Mercado Pago. Al
          utilizar estos servicios, aceptas sus términos y condiciones
          respectivos. coderplate40 no almacena información de tarjetas de
          crédito ni datos de cuentas bancarias. Registro de Usuario: Para
          acceder a ciertos recursos y funciones, debes registrarte como
          usuario. Asumes la responsabilidad de mantener la confidencialidad de
          tu información de inicio de sesión y aceptas notificarnos
          inmediatamente cualquier uso no autorizado de tu cuenta. Contenido del
          Usuario: Al proporcionar contenido, como comentarios o contribuciones,
          garantizas que tienes los derechos necesarios sobre dicho contenido y
          nos otorgas el derecho irrevocable de utilizar, modificar y distribuir
          dicho contenido en relación con nuestros servicios. Propiedad
          Intelectual: Todos los derechos de propiedad intelectual asociados con
          los servicios de coderplate40, incluyendo pero no limitado a textos,
          gráficos, logotipos y software, son propiedad de coderplate40 o sus
          licenciantes. Cancelación y Reembolso: Los detalles sobre
          cancelaciones y reembolsos se encuentran especificados en nuestra
          política de cancelación y reembolso, disponible en nuestro sitio web.
          Limitación de Responsabilidad: coderplate40 no se hace responsable de
          cualquier daño directo, indirecto, incidental, especial o consecuente
          que pueda surgir del uso de nuestros servicios. Modificaciones de
          Términos: Nos reservamos el derecho de modificar estos términos en
          cualquier momento. Las modificaciones entrarán en vigencia
          inmediatamente después de ser publicadas en nuestro sitio web.
          Jurisdicción y Ley Aplicable: Estos términos y condiciones se rigen
          por las leyes, y cualquier disputa estará sujeta a la jurisdicción
          exclusiva de los tribunales. Contacto: Si tienes preguntas o
          comentarios sobre estos términos y condiciones, contáctanos a través
          de coderplate40@gmail.com.
        </p>
        <strong>Gracias por elegir coderplate40.</strong>
      </div>
    </div>
  );
}
