import "../styles/footer.css";
import logo from "../../img/logo1.png";
import SeparadorVertical from "../Componentes_individuales/SeparadorVertical";
import mp from "./../../img/img/mercado-pago.webp";
import paypal from "./../../img/img/paypal.webp";
import visa from "./../../img/img/visa.png";
import mastercard from "./../../img/img/mastercard.png";
import { Link } from "react-router-dom";

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <section className="container-footer">
      <div className="top-part">
        <div className="logo-footer">
          <img
            src={logo}
            alt="logo sitios web paginas ecommerce coderplate40"
            className="img"
          />
          {/* <h1>CoderePlate</h1> */}
        </div>
        <SeparadorVertical />
        <div className="info-footer">
          <ul className="ul-footer">
            <li>
              <a href="https://wa.me/1161855195" target="_blak">
                <i className="fa-brands fa-square-whatsapp"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/coderplate40/">
                <i className="fa-brands fa-square-instagram"></i>
              </a>
            </li>
            <li className="desactive">
              <a
                target="_blak"
                href="https://mail.google.com/mail/u/1/#inbox?compose=GTvVlcSDZqfmrrkRnNKnkgfNrGlsQdBFBhXpGzGLNBBglxVzMfTjZMLxsZCvKGwTPcDqRVJKvxSWWs"
              >
                <i className="fa-solid fa-envelope"></i>
              </a>
            </li>
            <li className="active">
              <a href="mailto:coderplate40@gmail.com">
                <i className="fa-solid fa-envelope"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="rights">
        <div className="politicas-rights">
          <Link to="/terminos-y-condiciones">Términos y condiciones</Link>
          {/* <p className="palito-footer">|</p>
          <p>Política de privacidad</p>
          <p className="palito-footer">|</p>
          <p>Política de Cookies</p> */}
        </div>
        <div>
          <p>© {year} coderplate40 | Todos los derechos reservados.</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
