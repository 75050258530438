import ComponentService from "./ComponentService";
import { Link } from "react-router-dom";
import "./stylesCommon/ComponentsServices.css";
import eeuu from "./../../../img/img/estados-unidos.webp";
import argentina from "./../../../img/img/argentina.webp";
import { Helmet } from "react-helmet";

const ComponentsServices = ({ projectData, title }) => {
  const selectedProjects = projectData.slice(0, 6);

  return (
    <div className="projects" id="proyectos">
      <main>
        <div className="list-projects">
          {selectedProjects.map((project, index) => {
            return (
              <div className="projects__item" key={index}>
                <ComponentService
                  id={project.id}
                  imagen={project.imagen}
                  tecnologia1={project.tecnologias1}
                  tecnologia2={project.tecnologias2}
                  tecnologia3={project.tecnologias3}
                  tecnologia4={project.tecnologias4}
                  tecnologia5={project.tecnologias5}
                  tecnologia6={project.tecnologias6}
                  titulo={project.titulo}
                  text={project.text}
                  alt={project.alt}
                  ruta={project.ruta}
                  comprar={project.comprar}
                  precio={project.precio}
                  precioViejo={project.precioViejo}
                  textBtn={project.textBtn}
                />
              </div>
            );
          })}
        </div>
      </main>
    </div>
  );
};

export default ComponentsServices;
