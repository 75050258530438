import BackBtn from "../../BackBtn";
import ComponentsServices from "../ComponentsServices";
import VerProducto from "../VerProducto";
import SitiosWeb from "./SitiosWeb";
export default function VerSitiosWeb({}) {
  return (
    <>
      <section id="Plantillas">
        <BackBtn ruta="/" />
        <div className="container">
          <VerProducto
            ruta="/"
            titulo="¡Tu Sitio web Personalizado!"
            subtitulo="¡Los sitios web Se pueden pagar en hasta 3 cuotas sin interes!."
          />
          <ComponentsServices
            title="¡Tu Sitio web Personalizado!"
            projectData={SitiosWeb}
          />
        </div>
      </section>
    </>
  );
}
