import { useEffect, useState } from "react";
import "./App.css";
import Home from "./components/Home";
import "./components/variables.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import VerSitiosWeb from "./components/common/service/SitiosWeb/VerSitiosWeb";
import Contacto from "./components/common/contacto/Contacto";
import ScrollToTop from "./ScrollToTop";
import ServicioIndividual from "./components/common/service/ServicioIndividual";
import SitiosWeb from "./components/common/service/SitiosWeb/SitiosWeb";
import Footer from "./components/Home/Footer";
import TerminosYCondiciones from "./components/TerminosYCondiciones";
import Share from "./share/Share";
import VerPlantillas from "./components/common/service/Plantillas/VerPlantillas";
import Plantillas from "./components/common/service/Plantillas/Plantillas";
function App() {
  const [activeMenuResponsive, setActiveMenuResponsive] = useState(false);
  const [userLogin, setUser] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // changeUser(setUser);
  }, []);

  return (
    <>
      <div className="App">
        <div onClick={() => setIsOpen(false)}>
          <BrowserRouter>
            <div>
              <ScrollToTop />
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                      userLogin={userLogin}
                      activeMenuResponsive={activeMenuResponsive}
                      setActiveMenuResponsive={setActiveMenuResponsive}
                    />
                  }
                />
                <Route
                  path="/terminos-y-condiciones"
                  element={<TerminosYCondiciones />}
                />

                <Route
                  path="/consultar"
                  element={
                    <Contacto
                      ocultarBtn={true}
                      setActiveMenuResponsive={setActiveMenuResponsive}
                    />
                  }
                />

                <Route
                  path="/SitiosWeb"
                  element={
                    <VerSitiosWeb
                      setActiveMenuResponsive={setActiveMenuResponsive}
                    />
                  }
                />
                <Route
                  path="/SitiosWeb/:titulo"
                  element={
                    <ServicioIndividual
                      SitiosWeb={SitiosWeb}
                      ocultarBtn={true}
                      setActiveMenuResponsive={setActiveMenuResponsive}
                    />
                  }
                />

                <Route
                  path="/plantillas"
                  element={
                    <VerPlantillas
                      setActiveMenuResponsive={setActiveMenuResponsive}
                    />
                  }
                />
                <Route
                  path="/plantillas/:titulo"
                  element={
                    <ServicioIndividual
                      SitiosWeb={Plantillas}
                      comprar={true}
                      userLogin={userLogin}
                      ocultarBtn={true}
                      setActiveMenuResponsive={setActiveMenuResponsive}
                    />
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              <Footer />
            </div>
          </BrowserRouter>
        </div>
        {/* <div
          style={{
            zIndex: "9999",
            position: "fixed",
            bottom: "5%",
            right: "3%",
          }}
        >
          <Share isOpen={isOpen} setIsOpen={setIsOpen} />
        </div> */}
      </div>
    </>
  );
}

export default App;
