import "./TiposServicio.css";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import Footer from "../../../Home/Footer";

const TiposServicio = ({
  titulo,
  text,
  descripcion,
  VerSitiosWeb,
  precio,
  precioViejo,
  imagen,
  LinkVer,
}) => {
  return (
    <div className="container-tipo-servicio">
      <div className="container-description">
        <div className="container-img">
          <img
            src={imagen}
            alt={`${titulo} de Servicios CoderePlate`}
            title={`${titulo} Imagen Servicio CoderePlate`}
          />
        </div>
        <div className="description">
          <h1>{titulo}</h1>
          <div className="separator-description"></div>
          <p>{descripcion}</p>
          <p>{text}</p>
          <div className="part-btn-description">
            <div className="btn-bg bg-1">
              <div className="btn-consultar btn-1">
                <ScrollLink to="Contacto" offset={0} duration={450}>
                  <button>Consultar</button>
                </ScrollLink>
              </div>
            </div>

            <div className="btn-bg bg-1">
              <div className="btn-consultar btn-1">
                <a href={LinkVer}>
                  <button>{VerSitiosWeb}</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TiposServicio;
