import BackBtn from "../../BackBtn";
import ComponentsServices from "../ComponentsServices";
import VerProducto from "../VerProducto";
import Plantillas from "./Plantillas";
import "./Plantillas.css";
export default function VerPlantillas({}) {
  return (
    <>
      <section
        className=""
        style={{ width: "90%", margin: "auto", color: "white" }}
      >
        <BackBtn ruta="/" />
        <br />
        {/* <h1 className="cartel-Atencion">
          ¡Las Plantillas las Compras una vez con tu cuenta y la podes descargar
          las veces que quieras sin limite!
        </h1> */}
        <div id="Plantillas">
          <VerProducto
            ruta="/"
            titulo="¡Plantillas de Sitios Web!"
            subtitulo="Plantillas hechas a medida."
          />
          <ComponentsServices
            projectData={Plantillas}
            title="Plantillas Web Gratis"
          />
        </div>
      </section>
    </>
  );
}
