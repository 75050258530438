import { Link } from "react-router-dom";
import Titulo from "../../Componentes_individuales/Titulo";
import SeparadorTitulo from "../../Componentes_individuales/SeparadorTitulo";
import BtnVolver from "../../Componentes_individuales/BtnVolver";
import Footer from "../../Home/Footer";

export default function VerProducto({ ruta, titulo, subtitulo }) {
  return (
    <>
      <div className="container-titulo-servicios">
        <div>
          <Titulo titulo={titulo} />
          <SeparadorTitulo />
          <h5>{subtitulo}</h5>
        </div>
      </div>
    </>
  );
}
