import { useEffect, useState } from "react";
import logo from "../../img/logo2.png";
import "../styles/navbar.css";
import { Link as ScrollLink } from "react-scroll";
import { Link, useParams } from "react-router-dom";
export default function Navbar({
  userLogin,
  activeMenuResponsive,
  setActiveMenuResponsive,
}) {
  const [scrolledDown, setScrolledDown] = useState(false);

  const handleActiveMenuResponsive = () => {
    setActiveMenuResponsive(!activeMenuResponsive);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolledDown(true);
      } else {
        setScrolledDown(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Limpieza del evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // El segundo argumento [] asegura que este efecto se ejecute solo una vez, como componentDidMount

  const containerClassName = scrolledDown ? "scroll-down" : "";

  return (
    <nav className={`navbar-container ${containerClassName}`}>
      <ScrollLink
        to="Home"
        onClick={() => setActiveMenuResponsive(false)}
        offset={-70}
        duration={450}
      >
        <div className="logo">
          <img
            src={logo}
            title="Logo coderplate40 Empresa de Desarrollo, Mantenimiento y Hosting de Páginas Web "
            alt="coderplate40 - Plataforma de Desarrollo, Mantenimiento y Hosting de Páginas Web"
          />
        </div>
      </ScrollLink>
      <div className="content-navbar">
        <ul className={`list-navbar ${activeMenuResponsive ? "active" : ""}`}>
          <Link onClick={() => setActiveMenuResponsive(false)} to="SitiosWeb">
            <li>Sitíos Web</li>
          </Link>
          {/* <Link onClick={() => setActiveMenuResponsive(false)} to="plantillas">
            <li>Plantillas</li>
          </Link> */}
          <ScrollLink
            onClick={() => setActiveMenuResponsive(false)}
            to="Quienes-Somos"
            offset={0}
            duration={450}
          >
            <li>¿Quiénes Somos?</li>
          </ScrollLink>
          <ScrollLink
            onClick={() => setActiveMenuResponsive(false)}
            to="Contacto"
            offset={0}
            duration={450}
          >
            <li>Contacto</li>
          </ScrollLink>
          {/* <ScrollLink
            onClick={() => setActiveMenuResponsive(false)}
            to="Opiniones"
            offset={-70}
            duration={450}
          >
            <li>Opiniones</li>
          </ScrollLink> */}
          {/* {userLogin ? (
            <div className="btn-login-register">
              <button onClick={() => handleLogout()}>Cerrar Sesión</button>
            </div>
          ) : (
            <>
              <div className="btn-login-register">
                <button onClick={() => (window.location.href = "/login")}>
                  Iniciar sesión
                </button>
                <button onClick={() => (window.location.href = "/register")}>
                  Register
                </button>
              </div>
            </>
          )} */}
        </ul>
        <div className="btn-responsive">
          <i
            className="fa-solid fa-bars"
            onClick={handleActiveMenuResponsive}
          ></i>
        </div>
      </div>
    </nav>
  );
}
