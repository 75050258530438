import React from "react";
import { Link } from "react-router-dom";

export default function BackBtn({ ruta }) {
  return (
    <Link className="btn-voler" to={ruta}>
      Volver
    </Link>
  );
}
