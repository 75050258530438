import SeparadorTitulo from "../Componentes_individuales/SeparadorTitulo";
import Titulo from "../Componentes_individuales/Titulo";
import TiposServicio from "../common/service/tipoServicios/TiposServicio";
import "../styles/Producto.css";
export default function Producto({
  id,
  text,
  titulo,
  subtitulo,
  tituloService,
  VerSitiosWeb,
  DescripcionService,
  LinkVer,
  paginaweb,
}) {
  return (
    <section id={id}>
      <div id={id} className="box-container-servicios">
        <div className="container-titulo-servicios">
          <Titulo titulo={titulo} />
          <SeparadorTitulo />
          <h5>{subtitulo}</h5>
        </div>
        <div className="container-servicios">
          <TiposServicio
            titulo={tituloService}
            descripcion={DescripcionService}
            text={text}
            VerSitiosWeb={VerSitiosWeb}
            imagen={paginaweb}
            LinkVer={LinkVer}
          />
        </div>
      </div>
    </section>
  );
}
