import Blogs from "./img/Blogs.webp";
import Blogsnoticias from "./img/Blogsnoticias.webp";
import Portfolio from "./img/Porfolio.webp";
import Sitios_educativos from "./img/Sitios_educativos.webp";
import SitiosWebdeViajesLandingPage from "./img/Sitios-Web-de-Viajes-Landing-Page.webp";
import ForosyComunidadesenLínea from "./img/Foros-y-Comunidades-en-Línea.webp";
import Ecommerce from "./img/Ecommerce.webp";
import PáginasWebDinámicas_Especiales from "./img/Páginas-Web-Dinámicas-_Especiales_.webp";

const SitiosWeb = [
  {
    id: 1,
    imagen: Blogs,
    titulo: "Blogs",
    ruta: "/SitiosWeb",
    textBtn: "consultar",
    precio: 0,
    precioViejo: "100",
    alt: "Imagen sitio web blog de coderplate40 ",
    descripcion:
      "Los blogs son sitios web que presentan contenido regularmente actualizado, generalmente en formato de artículo. Pueden cubrir una amplia variedad de temas, desde noticias hasta experiencias personales o especializaciones temáticas.",
    ventajas: `Contenido Fresco La actualización regular mantiene a los lectores comprometidos.
    Interactividad Los comentarios permiten la interacción entre los lectores y el autor.
    Posicionamiento como Experto Puede establecer a los autores como expertos en su campo",`,
    impactoMercado:
      "Los blogs son herramientas poderosas para compartir información y establecer la autoridad en un nicho específico. Han transformado la forma en que las personas consumen contenido en línea.",
    text: "Un blog es un sitio web que publica contenido regularmente en formato de entradas cronológicas.",
  },
  // {
  //   id: 2,
  //   imagen: Blogsnoticias,
  //   titulo: "Blogs Noticias",
  //   textBtn: "consultar",
  //   text: "textMonetrix",
  // },
  {
    id: 2,
    imagen: Portfolio,
    titulo: "Portfolio",
    ruta: "/SitiosWeb",
    textBtn: "consultar",
    precio: 0,
    precioViejo: "300",
    alt: "Imagen sitio web portfolio de coderplate40 ",
    descripcion:
      "Los sitios de portafolios son plataformas diseñadas para mostrar trabajos, proyectos o habilidades de un individuo o empresa. Comúnmente utilizados por profesionales creativos como diseñadores, fotógrafos y desarrolladores.",
    ventajas: `Showcase de Habilidades Exhibe de manera efectiva el trabajo y las habilidades del creador.
    Marketing Personal Ayuda a individuos a destacar en sus campos respectivos.
    Prueba de Competencia Permite a los clientes potenciales ver trabajos anteriores.`,
    impactoMercado:
      "Los portafolios en línea son esenciales para profesionales creativos que buscan oportunidades de empleo o clientes. Son una herramienta clave para destacar en un mercado competitivo.",
    text: "Un sitio web de portafolio es una plataforma que muestra y destaca el trabajo, proyectos y habilidades de un individuo o empresa.",
  },
  {
    id: 3,
    imagen: Sitios_educativos,
    titulo: "Sitios Educativos",
    ruta: "/SitiosWeb",
    textBtn: "consultar",
    precio: 0,
    precioViejo: "350",
    alt: "Imagen sitio web sitios educativos de coderplate40 ",
    descripcion:
      "Los sitios educativos proporcionan contenido educativo, cursos en línea, tutoriales y recursos para el aprendizaje. Pueden variar desde plataformas formales de educación en línea hasta blogs educativos.",
    ventajas: `Acceso Global Permite el aprendizaje desde cualquier parte del mundo.
    Variedad de Contenido Puede incluir videos, textos, tareas y evaluaciones.
    Escalabilidad Puede atender a grandes audiencias simultáneamente.`,
    impactoMercado:
      "Los sitios educativos han democratizado la educación, brindando oportunidades de aprendizaje a personas de todas partes y facilitando la actualización constante de habilidades.",
    text: "Plataforma en línea diseñada para proporcionar información educativa, cursos, tutoriales o recursos didácticos.",
  },
  {
    id: 4,
    imagen: ForosyComunidadesenLínea,
    titulo: "Foros En línea",
    ruta: "/SitiosWeb",
    textBtn: "consultar",
    precio: 0,
    precioViejo: "350",
    alt: "Imagen sitio web foros en linea de coderplate40 ",
    descripcion:
      "Los foros en línea son plataformas de discusión donde los usuarios pueden intercambiar ideas, hacer preguntas y compartir información sobre diversos temas. Pueden ser especializados o cubrir una amplia gama de temas.",
    ventajas: `Comunidad Fomenta la interacción y construcción de comunidades.
    Resolución de Problemas Los usuarios pueden obtener ayuda para problemas específicos.
    Intercambio de Conocimientos Facilita el intercambio de información y experiencias.`,
    impactoMercado:
      "Los foros en línea han sido fundamentales para la creación de comunidades en línea y han desempeñado un papel importante en la difusión de conocimientos y la conexión de personas con intereses similares.",
    text: "Plataforma interactiva que permite a los usuarios discutir temas, hacer preguntas y compartir información en línea.",
  },
  {
    id: 5,
    imagen: SitiosWebdeViajesLandingPage,
    titulo: "Landing Page",
    ruta: "/SitiosWeb",
    textBtn: "consultar",
    precio: 0,
    precioViejo: "200",
    alt: "Imagen sitio web landing page de coderplate40 ",
    descripcion:
      "Una landing page es una página web diseñada específicamente para convertir visitantes en leads o clientes. Se centra en una llamada a la acción (CTA) clara y suele ser parte de una estrategia de marketing.",
    ventajas: `Enfoque Singular Se centra en un objetivo específico.
    Conversión Efectiva Diseñada para maximizar la tasa de conversión.
    Rastreo y Análisis Simplificados Facilita el seguimiento de métricas específicas.
    `,
    impactoMercado:
      "Las landing pages son fundamentales en campañas de marketing digital, ya que proporcionan un punto de entrada focalizado para convertir visitantes en clientes potenciales o clientes.",
    text: "Una landing page es una página web diseñada con un objetivo específico, como promocionar un producto, captar leads o incentivar una acción.",
  },
  {
    id: 6,
    imagen: Ecommerce,
    titulo: "E-commerce",
    ruta: "/SitiosWeb",
    textBtn: "consultar",
    precio: 0,
    precioViejo: "600",
    alt: "Imagen sitio web ecommerce tiendas online de coderplate40 ",
    descripcion:
      "Los sitios de comercio electrónico permiten la compra y venta de productos o servicios en línea. Pueden variar desde pequeñas tiendas en línea hasta grandes plataformas de comercio electrónico.",
    ventajas: `Acceso Global al Mercado Amplía el alcance de los negocios.
    Facilidad de Compra Permite a los clientes comprar desde la comodidad de sus hogares.
    Personalización Ofrece la posibilidad de personalizar la experiencia de compra.`,
    impactoMercado:
      "El comercio electrónico ha revolucionado la forma en que se realizan las transacciones comerciales, permitiendo a las empresas llegar a clientes de todo el mundo y brindar opciones de compra convenientes.",
    text: "Un sitio web de comercio electrónico permite la compra y venta de productos o servicios en línea.",
  },
  // {
  //   id: 7,
  //   imagen: PáginasWebDinámicas_Especiales,
  //   titulo: "PiedraPapelOTijerasTitulo",
  //   ruta: "/SitiosWeb",
  //   textBtn: "consultar",
  //   precio: "desde 49.99",
  //   descripcion: "",
  //   ventaja: "",
  //   impactoMercado: "",
  //   precioViejo: "100",
  //   text: "textPiedraPapelOTijeras",
  // },
  // {
  //   id: 9,
  //   imagen: SimonGame,
  //   titulo: "SimonGameTitulo",
  //   ruta: "/SitiosWeb",
  //   tecnologias: "HTML, CSS and JS",
  //   textBtn: "consultar",
  //   text: "textSimonGame",
  //   linkWebside: "https://simon-dice-js.netlify.app/",
  //   linkRepository: "https://github.com/kevingonzalo/simon-dice-js",
  // },
  // {
  //   id: 10,
  //   imagen: TaTeTiGame,
  //   titulo: "Ta-Te-Ti Game",
  //   ruta: "/SitiosWeb",
  //   tecnologias: "Reactjs.",
  //   textBtn: "consultar",
  //   text: "textTaTeTiGame",
  //   linkWebside: "https://ta-te-ti-reactjs.netlify.app/",
  //   linkRepository: "https://github.com/kevingonzalo/Ta-Te-Ti-REACTJS",
  // },
  // {
  //   id: 11,
  //   imagen: CalculadoraJs,
  //   titulo: "CalculadoraJsTitulo",
  //   ruta: "/SitiosWeb",
  //   tecnologias: "HTML, CSS and JavaScript.",
  //   textBtn: "consultar",
  //   text: "textCalculadoraJs",
  //   linkWebside: "https://calculadorawithjs.netlify.app/",
  //   linkRepository: "https://github.com/kevingonzalo/Calculadora-js",
  // },
  // {
  //   id: 12,
  //   imagen: CuentaRegresiva,
  //   titulo: "Ta-Te-Ti Game",
  //   ruta: "/SitiosWeb",
  //   tecnologias: "HTML, CSS and JavaScript.",
  //   textBtn: "consultar",
  //   text: "textCuentaRegresiva",
  //   linkWebside: "https://cuenta-regresivajs.netlify.app/",
  //   linkRepository: "https://github.com/kevingonzalo/Cuenta-Regresiva-JS",
  // },
];
export default SitiosWeb;
