import { useState } from "react";
import Navbar from "./Home/Navbar";
import Header from "./Home/Header";
import Opinions from "./common/opinions/Opinions";
import Contacto from "./common/contacto/Contacto";
import "../components/Componentes_individuales/Compontes_individuales.css";
import AboutUs from "./common/aboutUs/AboutUs";
import Footer from "./Home/Footer";
import paginaweb from "./common/service/tipoServicios/imgServices/Pagina-web.webp";
import Producto from "./Home/Producto";
import plantillasImg from "./common/service/tipoServicios/imgServices/plantillas.webp";
import CursosImg from "./common/service/tipoServicios/imgServices/cursos.webp";
import Share from "../share/Share";
export default function Home({
  userLogin,
  activeMenuResponsive,
  setActiveMenuResponsive,
}) {
  const [Asunto, setAsunto] = useState();

  return (
    <>
      <Navbar
        userLogin={userLogin}
        activeMenuResponsive={activeMenuResponsive}
        setActiveMenuResponsive={setActiveMenuResponsive}
      />
      <div
        className="home"
        id="Home"
        onClick={() => setActiveMenuResponsive(false)}
      >
        <div>
          <Header />
          <div className="App-container">
            <Producto
              id="Servicios"
              titulo="¡Tu Sitio web Personalizado!"
              subtitulo="Descubre el Poder de la Presencia en Línea."
              tituloService="Página Web para tu Negocio💣"
              DescripcionService="Realizamos sitios Web a medida solo necesitamos tu Idea de Negocio para Desarrollar la web para tu empresa o emprendimiento Personal a precio superaccesibles, No dudes en consultar.💰"
              LinkVer="/SitiosWeb"
              VerSitiosWeb="ver Sitios Web"
              paginaweb={paginaweb}
            />
            {/* <Producto
              id="Plantillas"
              titulo="¡Plantillas de Sitios Web!"
              subtitulo="Sitios Web hechos a la medida listos para usar."
              tituloService="Plantillas Para tu Negocio ✔"
              DescripcionService="Una Plantilla es un sitio web ya terminado para que solo tengas que modificar el contenido a tus necesidades y preferencias. son especiales para ahorrar tiempo y dinero 😊.
               se entrega al instante luego de realizar la compra. ¡Una vez hecho el pago, puede Descargarse con tu cuenta las veces que quieras sin limite!.🤞"
              LinkVer="/plantillas"
              VerSitiosWeb="ver Plantillas"
              paginaweb={plantillasImg}
            /> */}
            {/* <Producto
              id="Cursos"
              titulo="¡Cursos de Programación!"
              subtitulo="Cursos con Seguimiento Particular al Mejor Precio."
              tituloService="Cursos de Programación con Certificado 😎"
              DescripcionService="Los Cursos incluyen Ejercicios teoricos y Practicos, Videos Explicativos, un Tutor Particular que te guiara en todo Momento para que pueda despejar todas tus dudas, Certificado al aprobar el trabajo Final y cada uno de los Cursos que desees Realizar!.💻"
              LinkVer="/Cursos"
              VerSitiosWeb="ver Cursos"
              paginaweb={CursosImg}
            /> */}
            {/* <Opinions /> */}
            <AboutUs />
            <Contacto ocultarBtn={false} />
            {/* <SeparadorContact /> */}
          </div>
        </div>
      </div>
    </>
  );
}
