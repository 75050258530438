import "../styles/header.css";
import "../styles/animaciones-header.css";
import "../styles/personaje-animado.css";
import PersonaAnimadoHeader from "./PersonaAnimadoHeader";
import { Link as ScrollLink } from "react-scroll";
import backgournd from "../../img/fondo-header.webp";
export default function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <div className="Personaje-responsive">
          <PersonaAnimadoHeader />
        </div>
        <div className="header-content-right">
          <h5>La web ideal para tu Negocio</h5>
          <h4 className="wordCarousel">
            <span>💻 Sitios Web:</span>
            <div>
              <ul className="flip4">
                <li>Profesionales</li>
                <li>Estéticos</li>
                <li>Personalizados</li>
                <li>Precios Accesibles</li>
              </ul>
            </div>
          </h4>
          <div className="btn">
            <ScrollLink to="Contacto" offset={0} duration={450}>
              <span>Contacto📞</span>
            </ScrollLink>
          </div>
        </div>
      </div>
    </header>
  );
}
