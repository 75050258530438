import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./stylesCommon/ServicioIndividual.css";
import VerProducto from "./VerProducto";
import SliderImagenes from "../../Componentes_individuales/SliderImagenes";
import { URL } from "../../../config";
import axios from "axios";
import eeuu from "../../../img/img/estados-unidos.webp";
import argentina from "../../../img/img/argentina.webp";
import { Helmet } from "react-helmet";
import BackBtn from "../BackBtn";
import { useNavigate } from "react-router-dom";
import ModalCompra from "./ModalCompra";
import { formatearPrecio } from "./ComponentService";
import YouTube from "react-youtube";

export default function ServicioIndividual({ SitiosWeb, comprar, userLogin }) {
  const [website, setWebsite] = useState([]);
  const { titulo } = useParams();
  const [esComprado, setEsComprado] = useState(false);
  const [moneda, setMoneda] = useState("USD");
  const [precioDolar, setPrecioDolar] = useState();
  const [precioDolarViejo, setPrecioDolarViejo] = useState();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [precio, setPrecio] = useState(0);

  useEffect(() => {
    if (website && website.precio) {
      setPrecio(website.precio);
    }
  }, [website]);

  const opts = {
    // height: "200",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const handleWebsite = async () => {
    const tituloWebSite = SitiosWeb.find(
      (project) => project.titulo === titulo
    );
    if (!tituloWebSite) {
      return;
    }
    setWebsite(tituloWebSite);
  };
  function redondearA999ConDecimal(numero) {
    const redondeado = Math.round(numero / 1000) * 1000 - 1;
    return (redondeado / 1000).toFixed(3);
  }
  useEffect(() => {
    if (moneda === "ARS") {
      const nuevoPrecio = redondearA999ConDecimal(
        (Math.ceil(parseFloat(website.precio)) * 1125) / 2
      );
      const nuevoPrecioViejo = redondearA999ConDecimal(
        Math.ceil((parseFloat(website.precioViejo) * 1125) / 2)
      );
      setWebsite((prevWebsite) => ({
        ...prevWebsite,
        precio: nuevoPrecio,
        precioViejo: nuevoPrecioViejo,
      }));
    } else {
      setWebsite((prevWebsite) => ({
        ...prevWebsite,
      }));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleWebsite();
        setMoneda(localStorage.getItem("moneda"));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const handleComprar = () => {
    setShowModal(true);
  };
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Desarrollo, venta y creación Sitios Web personalizados para tu negocio o empresa, Curso de Desarrollo web, Plantillas web Gratis Personalizadas."
        />
        <meta
          name="keywords"
          content={`Páginas web, ${website.titulo}, ${website.ruta}, Compra de Páginas web, Sitio web para tu Negocio o empresa, Desarrollo Web.`}
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/Ydmwv1k/F58-JFx-SW8-AA-8h3-copia.png"
        />
        <title>coderplate40 - {`${website.titulo}`}</title>
        <meta
          property="og:url"
          content={`https://coderplate40.com${website.ruta}/${website.titulo}`}
        />
      </Helmet>
      <section>
        <Link className="btn-voler" onClick={() => navigate(-1)}>
          Volver
        </Link>
        <div className="container" id="proyectos">
          <div className="projects-content aparecer">
            <VerProducto ruta={website.ruta} titulo={website.titulo} />
            <div className="container-servicio-individual aparecer">
              <div className="container-izquierdo">
                <div>
                  {/* <YouTube videoId="_v6xS8DIVk8" opts={opts} /> */}
                  <img src={website.imagen} alt={website.titulo} />
                  {/* <p>Si tenes alguna pregunta no dudes en consultar </p> */}
                </div>
              </div>

              <div className="descripcion-servicio-individual">
                <p className="descripcion-web">
                  <strong>Descripción: </strong>
                  {website.text}
                </p>
                <p className="descripcion-web">
                  <strong>Ventajas: </strong>
                  {website.ventajas}
                </p>
                {/* <p>
                  <strong>Sitio de Muestra:</strong>{" "}
                  <a
                    style={{ color: "white" }}
                    target="_blank"
                    href="https://coderplate40.com"
                  >
                    https://coderplate40.com
                  </a>
                </p> */}

                {/* <h4>${formatearPrecio(precio)} ARS o $5 USD</h4> */}

                <div className="btn-precios-container">
                  <div className="project-bottom-container individual">
                    {website.ruta === "/SitiosWeb" ? (
                      <></>
                    ) : (
                      <>
                        <button
                          style={{
                            border: "none",
                            background: "black",
                            padding: "0.5rem 1rem",
                            color: "white",
                            borderRadius: "5px",
                            border: "1px solid white",
                          }}
                          onClick={handleComprar}
                        >
                          Comprar ✔
                        </button>
                      </>
                    )}
                    <button
                      style={{
                        border: "none",
                        background: "black",
                        padding: "0.5rem 1rem",
                        color: "white",
                        borderRadius: "5px",
                        border: "1px solid white",
                      }}
                      onClick={() => (window.location.href = "/consultar")}
                      rel="noopenr noreferrer"
                    >
                      📞 Consultar
                    </button>
                  </div>
                </div>
                {/* <p>{website.cantVendidas} vendidas esta semana</p> */}
              </div>
            </div>
          </div>

          <ModalCompra
            showModal={showModal}
            setShowModal={setShowModal}
            website={website}
          />
        </div>
      </section>
    </>
  );
}
