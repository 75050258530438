import React, { useState } from "react";
import { Checkbox, FormControlLabel, Button, TextField } from "@mui/material";
import axios from "axios";
import "./SitiosWeb/SitiosWeb.css";
import { URL } from "../../../config";

export default function ModalCompra({ showModal, setShowModal, website }) {
  const [email, setEmail] = useState("");
  const [metodoPago, setMetodoPago] = useState("mercadoPago"); // Mercado Pago por defecto
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para manejar el botón

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMetodoPagoChange = (e) => {
    setMetodoPago(e.target.value);
  };

  const handleSubmit = async () => {
    if (!email) {
      setError("El email es requerido");
      return;
    }

    if (!metodoPago) {
      setError("Debe seleccionar al menos un método de pago");
      return;
    }

    setIsLoading(true); // Desactivar el botón

    try {
      let response;
      if (metodoPago === "paypal") {
        response = await axios.post(`${URL}/realizar-compra-paypal`, {
          email,
          metodoPago,
          website,
        });
      } else {
        response = await axios.post(`${URL}/realizar-compra`, {
          email,
          metodoPago,
          website,
        });
      }

      if (response.status === 200) {
        if (metodoPago === "paypal") {
          window.location.href = response.data;
          // Redirigir a la página de PayPal
        } else {
          window.location.href = response.data.approvalUrl;
        }
        setShowModal(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Reactivar el botón
    }
  };

  return (
    <div className={`modal-comprar ${showModal ? "active" : ""}`}>
      <div className="modal-content">
        <h2>Ingrese su Email</h2>
        <div>
          <TextField
            type="email"
            placeholder={`Correo Electrónico de la compra (por favor la sección revise "SPAM")`}
            value={email}
            onChange={handleEmailChange}
            fullWidth
            error={!!error}
            helperText={error && email === "" ? error : ""}
          />
        </div>
        <div
          className="checkbox-group"
          style={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            textTransform: "uppercase",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={metodoPago === "mercadoPago"}
                onChange={handleMetodoPagoChange}
                value="mercadoPago"
                sx={{
                  color: "blue",
                  "&.Mui-checked": {
                    color: "blue",
                  },
                }}
              />
            }
            label="Mercado Pago"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={metodoPago === "paypal"}
                onChange={handleMetodoPagoChange}
                value="paypal"
                sx={{
                  color: "blue",
                  "&.Mui-checked": {
                    color: "blue",
                  },
                }}
              />
            }
            label="PayPal"
          />
        </div>

        {error && metodoPago === "" && <p style={{ color: "red" }}>{error}</p>}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginTop: "1rem" }}
          disabled={isLoading} // Desactivar el botón mientras carga
        >
          {isLoading ? "Procesando..." : "Comprar"}{" "}
          {/* Texto cambia cuando está cargando */}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setShowModal(false)}
          style={{ marginTop: "1rem" }}
        >
          Cerrar Modal
        </Button>
      </div>
    </div>
  );
}
